import { getBrandPage, getTaskitemTypeStat, getSupplierStat } from '@/api/msp/workday'

const getDefaultState = () => {
  return {
    startDate: '',
    endDate: '',
    beginUpdate: new Date(),
    assetId: null,
    stationId: null,
    actionDate: '',
    planDetail: {},
    selectedTaskItemIds: [],
    brandPageQuery: {
      pageSize: 15,
      pageNumber: 1
    },
    brandTableData: {},
    workTypeQuery: {},
    workTypeData: [],
    supplierStatQuery: {},
    supplierStatData: []
  }
}
const state = getDefaultState()
const board = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_board_startDate (state, data) {
      state.startDate = data
    },
    set_board_endDate (state, data) {
      state.endDate = data
    },
    set_board_assetId (state, data) {
      state.assetId = data
    },
    set_board_stationId (state, data) {
      state.stationId = data
    },
    set_board_actionDate (state, data) {
      state.actionDate = data
    },
    set_board_planDetail (state, data) {
      state.planDetail = data
    },
    set_board_beginUpdate (state, data) {
      state.beginUpdate = data
    },
    set_board_selectedTaskItemIds (state, data) {
      state.selectedTaskItemIds = data
    },
    set_brand_page_query (state, data) {
      state.brandPageQuery = data
    },
    set_brand_table_data (state, data) {
      state.brandTableData = data
    },
    set_work_type_query (state, data) {
      state.workTypeQuery = data
    },
    set_work_type_data (state, data) {
      state.workTypeData = data || []
    },
    set_supplier_stat_query (state, data) {
      state.supplierStatQuery = data
    },
    set_supplier_stat_data (state, data) {
      state.supplierStatData = data || []
    }
  },
  getters: {

  },
  actions: {
    /**
     * 分页获取品牌作业计划数据
     * @param {Object} param0
     * @param {Object} query 查询条件参数
     */
    getBrandSummaryData ({ state, commit }, query) {
      const newQuery = Object.assign(state.brandPageQuery, query)
      commit('set_brand_page_query', newQuery)
      return new Promise((resolve) => {
        getBrandPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_brand_table_data', res)
          } else {
            commit('set_brand_table_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
    * 获取作业类型统计数据
    * @param {Object} param0
    * @param {Object} query 查询条件参数
    */
    getWorkTypeData ({ state, commit }, query) {
      // const newQuery = Object.assign(state.workTypeQuery, query)
      // commit('set_work_type_query', newQuery)
      return new Promise((resolve) => {
        getTaskitemTypeStat(query).then(res => {
          if (res && !res.errcode) {
            commit('set_work_type_data', res)
          } else {
            commit('set_work_type_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
    * 获取作业供应商统计数据
    * @param {Object} param0
    * @param {Object} query 查询条件参数
    */
    getSupplierStatData ({ state, commit }, query) {
      // const newQuery = Object.assign(state.supplierStatQuery, query)
      // commit('set_supplier_stat_query', newQuery)
      return new Promise((resolve) => {
        getSupplierStat(query).then(res => {
          if (res && !res.errcode) {
            commit('set_supplier_stat_data', res)
          } else {
            commit('set_supplier_stat_data', {})
          }
          resolve(res)
        })
      })
    }
  }
}

export default board
