import { getTaskItemPage, getTaskApproveList } from '@/api/msp/taskitem'
import { getStatusStatistic, getCompanyStatistic, getDomainStatistic, getPriceStatistical, getTaskMspTotalCost } from '@/api/msp/taskitemstatistic'
import { getPictureResource } from '@/api/msp/status'
import { getResourcetypeStatisticList, getUninstallResourcePage } from '@/api/msp/uninstall'
import { getResourcePage } from '@/api/product/resource'

const getDefaultState = () => {
  return {
    taskId: null,
    taskQuery: {
      pageNumber: 1,
      pageSize: 15
    },
    taskItemQuery: {
      taskId: null,
      pageSize: 15,
      pageNumber: 1
    },
    taskType: null,
    taskTypeArray: [],
    taskItemData: {},
    statusStatisticArray: [],
    companyStatisticArray: [],
    domainStatisticArray: [],
    feeStatisticArray: [], // 费用分类汇总统计
    totalFeeStatistic: 0,
    pictureSourceArray: [], // 画面来源
    selectedTaskItemIds: [],
    resourcetypeQuery: {},
    resourcetypeCount: [], // 下刊资源类型数量统计
    selectedResourcetypeId: null,
    uninstallQuery: {
      pageNumber: 1,
      pageSize: 15
    },
    uninstallData: [],
    selectedResourceIdArray: [], // 下刊设置所选中的资源id集合
    selectedSideArray: [], // 下刊设置所选中的画面数集合
    selectedAssetId: null,
    newTaskQuery: {
      pageNumber: 1,
      pageSize: 15
    },
    newTaskResourceData: [],
    tempSelectedItem: [], // 临时存储分页选中项
    uninstallPicType: 1, // 下刊画面设置类型，1：主动派发下刊任务，2：销售申请下刊任务
    indexSearchParams: null, // 首页搜索参数
    taskApproveList: [], // 审批任务清单
    taskCostTypeArray: [],
    selectedSupplierId: null,
    programQuerySdate: '',
    programQueryEdate: '',
    uniqueCode: '',
    taskOrderId: null
  }
}
const state = getDefaultState()
const workOrder = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_task_id (state, data) {
      state.taskId = data
    },
    set_task_query (state, data) {
      Object.assign(state.taskQuery, (data instanceof Object) ? data : getDefaultState().taskQuery)
    },
    set_task_item_query (state, data) {
      state.taskItemQuery = data
    },
    set_task_type (state, data) {
      state.taskType = data
    },
    set_task_type_array (state, data) {
      state.taskTypeArray = data
    },
    set_task_item_data (state, data) {
      state.taskItemData = data
    },
    set_status_statistic_array (state, data) {
      state.statusStatisticArray = data
    },
    set_company_statistic_array (state, data) {
      state.companyStatisticArray = data
    },
    set_domain_statistic_array (state, data) {
      state.domainStatisticArray = data
    },
    set_fee_statistic_array (state, data) {
      state.feeStatisticArray = data
    },
    set_total_fee_statistic (state, data) {
      state.totalFeeStatistic = data
    },
    set_picture_source (state, data) {
      state.pictureSourceArray = data
    },
    set_selected_task_item_ids (state, data) {
      state.selectedTaskItemIds = data
    },
    set_resourcetype_count (state, data) {
      state.resourcetypeCount = data
    },
    set_resourcetype_query (state, data) {
      state.resourcetypeQuery = data
    },
    set_selected_resourcetype_id (state, data) {
      state.selectedResourcetypeId = data
    },
    set_uninstall_query (state, data) {
      state.uninstallQuery = data
    },
    set_uninstall_data (state, data) {
      state.uninstallData = data
    },
    set_selected_resourceId_array (state, data) {
      state.selectedResourceIdArray = data
    },
    set_selected_side_array (state, data) {
      state.selectedSideArray = data
    },
    set_selected_assetId (state, data) {
      state.selectedAssetId = data
    },
    set_new_task_query (state, data) {
      state.newTaskQuery = data
    },
    set_temp_selected_item (state, data) {
      state.tempSelectedItem = (data instanceof Array) ? data : []
    },
    set_new_task_resource_data (state, data) {
      state.newTaskResourceData = data
    },
    set_uninstall_pic_type (state, data) {
      state.uninstallPicType = data
    },
    set_index_search_params (state, data) {
      state.indexSearchParams = data
    },
    set_task_approve_list (state, data) {
      state.taskApproveList = data
    },
    set_task_cost_type_array (state, data) {
      state.taskCostTypeArray = data
    },
    set_selected_supplier_id (state, data) {
      state.selectedSupplierId = data
    },
    set_program_query_sdate (state, data) {
      state.programQuerySdate = data
    },
    set_program_query_edate (state, data) {
      state.programQueryEdate = data
    },
    set_unique_code (state, data) {
      state.uniqueCode = data
    },
    set_task_orderid (state, data) {
      state.taskOrderId = data
    }
  },
  actions: {
    /**
     * 分页获取任务详情
     * @param {*} param0
     * @param {*} query
     */
    getTaskItemData ({ state, commit }, query) {
      state.taskItemQuery.taskId = state.taskId
      const newQuery = Object.assign(state.taskItemQuery, query)
      commit('set_task_item_query', newQuery)

      return new Promise((resolve) => {
        getTaskItemPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_task_item_data', res)
          } else {
            commit('set_task_item_data', {})
          }
          resolve(res)
        })
      })
    },
    getStatusStatisticData ({ state, commit }) {
      return new Promise((resolve) => {
        getStatusStatistic({ taskId: state.taskId, notInStatus: '-2' }).then(res => {
          if (res && !res.errcode) {
            commit('set_status_statistic_array', res)
          }
          resolve(res)
        })
      })
    },
    getCompanyStatisticData ({ state, commit }) {
      return new Promise((resolve) => {
        getCompanyStatistic({ taskId: state.taskId }).then(res => {
          if (res && !res.errcode) {
            commit('set_company_statistic_array', res)
          }
          resolve(res)
        })
      })
    },
    getDomainStatisticData ({ state, commit }) {
      return new Promise((resolve) => {
        getDomainStatistic({ taskId: state.taskId }).then(res => {
          if (res && !res.errcode) {
            commit('set_domain_statistic_array', res)
          } else {
            commit('set_domain_statistic_array', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取指定任务费用统计
     * @param {Object} param0
     * @returns Array
     */
    getFeeStatisticData ({ state, commit }) {
      return new Promise((resolve) => {
        getPriceStatistical({ taskId: state.taskId }).then(res => {
          if (res && !res.errcode) {
            commit('set_fee_statistic_array', res)
          } else {
            commit('set_fee_statistic_array', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取指定任务总费用统计
     * @param {Object} param0
     * @returns Array
     */
    getTotalFeeStatisticData ({ state, commit }) {
      return new Promise((resolve) => {
        getTaskMspTotalCost({ taskId: state.taskId }).then(res => {
          if (res && !res.errcode) {
            commit('set_total_fee_statistic', res)
          } else {
            commit('set_total_fee_statistic', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取画面来源
     */
    getPictureSourceData ({ commit }) {
      return new Promise((resolve) => {
        getPictureResource().then(res => {
          if (res && !res.errcode) {
            commit('set_picture_source', res)
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取下刊资源类型数量统计
     */
    getUninstallResourceData ({ state, commit }, query) {
      const newQuery = Object.assign(state.resourcetypeQuery, query)
      commit('set_resourcetype_query', newQuery)

      return new Promise((resolve) => {
        getResourcetypeStatisticList(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_resourcetype_count', res)
            // if (res.length > 0) {
            //   commit('set_selected_resourcetype_id', res[0].resourceTypeId)
            // }
          } else {
            commit('set_resourcetype_count', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取下刊数据
     * @param {Object} param0
     * @param {Object} query
     */
    getUninstallData ({ state, commit }, query) {
      const newQuery = Object.assign(state.uninstallQuery, query)
      commit('set_uninstall_query', newQuery)

      return new Promise((resolve) => {
        getUninstallResourcePage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_uninstall_data', res)
          } else {
            commit('set_uninstall_data', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取主动派发任务资源数据
     * @param {Object} param0
     * @param {Object} query
     */
    getNewTaskResourceData ({ state, commit }, query) {
      const newQuery = Object.assign(state.newTaskQuery, query)
      commit('set_new_task_query', newQuery)

      return new Promise((resolve) => {
        getResourcePage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_new_task_resource_data', res)
          } else {
            commit('set_new_task_resource_data', [])
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取任务审批清单
     * @param {Object} param0
     * @param {Object} query
     */
    getTaskApproveListData ({ state, commit }) {
      const newQuery = { taskId: state.taskId }

      return new Promise((resolve) => {
        getTaskApproveList(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_task_approve_list', res)
          } else {
            commit('set_task_approve_list', [])
          }
          resolve(res)
        })
      })
    }
  }
}

export default workOrder
