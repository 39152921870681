<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component
          :is="curBottomComponent"
          :serviceType="2"
        ></component>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import workorderStoreModule from '@/store/modules/workOrder'

import boardStoreModule from '@/store/modules/board'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 左侧组件
    ActionDateView: () => import('@/components/workOrder/left/ActionDateView'),
    SetCompany: () => import('@/components/workOrder/left/SetCompany'),
    SetPictrueSource: () => import('@/components/workOrder/left/SetPictrueSource'),
    UninstallScreen: () => import('@/components/workOrder/left/UninstallScreenNew'),
    UninstallSetScreen: () => import('@/components/workOrder/left/UninstallSetScreen'),
    SetInspection: () => import('@/components/workOrder/left/SetInspection'),
    changeActionDate: () => import('@/components/dynamicboard/left/Workday'),
    changeSupplier: () => import('@/components/dynamicboard/left/SetCompany'),
    WorkList: () => import('@/components/dynamicboard/left/WorkList'),
    // 底部组件
    MaterialUpload: () => import('@/components/workOrder/bottom/MaterialUpload'),
    IndexTable: () => import('@/components/workOrder/bottom/IndexTable'),
    TaskItem: () => import('@/components/workOrder/bottom/TaskItem'),
    UninstallTable: () => import('@/components/workOrder/bottom/UninstallTable'),
    NewTaskResource: () => import('@/components/workOrder/bottom/NewTaskResource'),
    CancelTaskTable: () => import('@/components/workOrder/bottom/CancelTaskTable'),
    HangupTaskTable: () => import('@/components/workOrder/bottom/HangupTaskTable'),
    EleProgramList: () => import('@/components/workOrder/bottom/elecMedia/ProgramList'),
    EleProgramDetail: () => import('@/components/workOrder/bottom/elecMedia/ProgramDetail'),

    boardList: () => import('@/components/dynamicboard/bottom/Board'),
    planningDayDetail: () => import('@/components/dynamicboard/bottom/PlanningDayDetail'),
    boardDetail: () => import('@/components/dynamicboard/bottom/BoardDetail'),

    // 右侧组件
    TaskSummary: () => import('@/components/workOrder/right/TaskSummary'),
    BasicInfo: () => import('@/components/workOrder/right/BasicInfo'),
    ResourceStatistics: () => import('@/components/workOrder/right/ResourceStatistics'),
    boardTaskStatistics: () => import('@/components/dynamicboard/right/TaskSummary')
  },
  created () {
    if (!this.$store.hasModule('workOrder')) {
      this.$store.registerModule('workOrder', workorderStoreModule)
    }
    if (!this.$store.hasModule('board')) {
      this.$store.registerModule('board', boardStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(true)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setBottomComponent('IndexTable')
    this.setRightComponent('TaskSummary')
  },
  destroyed () {
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')

    // 重置state，并注销moudle
    this.$store.commit('reset_state')
    this.$store.unregisterModule('workOrder')
    this.$store.unregisterModule('board')
  }
}
</script>
